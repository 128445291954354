@keyframes phone-call {
    0% {
      box-shadow: 0 0 0.5em 1.5em gray;
    }
    50% {
      box-shadow: 0 0 0 0.25em gray;
    }
    100% {
      opacity: 1;
      box-shadow: 0 0 0.5em 1.5em gray;
    }
}

.phone-call {
  box-shadow: 0 0 0.5em 0.5em gray;
  animation: phone-call 5s infinite ease-in-out;
  content: '""';
}