.rcw-conversation-container > .rcw-header {
	background-color: #303030;
}

.rcw-message > .rcw-response {
	background-color: #39B9E5;
	color: white;
}

.rcw-message > .rcw-response > .rcw-message-text {
	background-color: #39B9E5;
	color: white;
}

.rcw-message > .rcw-client {
	background-color: #303030;
	color: white;
}

.rcw-message > .rcw-client > .rcw-message-text {
	background-color: #303030;
	color: white;
}

.rcw-launcher {
	background-color: #39B9E5;
}

.rcw-client .rcw-message-text {
	background-color: #303030;
}

@media screen and (max-width: 800px)
{
	.rcw-widget-container
	{
		width: 80vw;
		margin-bottom: 18vh;
	}
}

@keyframes run-animation {
    0% {
      transform: scale(0.98);
      opacity: 1;
      box-shadow: 0 0 0.3em 0.3em gray;
    }
    50% {
      transform: scale(1.02);
      opacity: 0.9;
      box-shadow: 0 0 0.25em 0.25em gray;
    }
    100% {
      transform: scale(0.98);
      opacity: 1;
      box-shadow: 0 0 0.3em 0.3em gray;
    }
}

.run-animation {
  box-shadow: 0 0 0.5em 0.5em gray;
  animation: run-animation 1.5s infinite ease-in-out;
  content: '""';
}

.with-business-expert {
  display: flex;
  flex-direction: row;
  background-color: #39B9E5;
  border-radius: 1em;
  width: 27em;
  height: 5em;
  align-items: center;
  justify-content: space-around;
  transition-property: width;
  transition-duration: 0.5s;
  margin-left:auto;
}

.with-business-expert > .business-expert {
	display: block;
	overflow: hidden;
	white-space: nowrap;
    transition-property: display;
    transition-duration: 0.5s;
}

@media screen and (max-width: 800px)
{
	.with-business-expert {
	  display: block;
	  background-color: #39B9E5;
	  border-radius: 2.5em;
	  width: 5em;
	  height: 5em;
	  margin-left:	auto;
	  margin-right: 2em;
	  padding:1em;
	}

	.with-business-expert > .business-expert {
		display: none;
	}
}

.without-business-expert {
  display: flex;
  flex-direction: row;
  background-color: #39B9E5;
  border-radius: 2.5em;
  width: 5em;
  height: 5em;
  align-items: center;
  justify-content: center;
  transition-property: width;
  transition-duration: 0.5s;
  margin-left:auto;
}

.without-business-expert > .business-expert {
  display: none;
  transition-property: display;
  transition-duration: 0.5s;
}

@media screen and (max-width: 800px)
{
	.without-business-expert {
	  display: block;
	  background-color: #39B9E5;
	  border-radius: 2.5em;
	  width: 5em;
	  height: 5em;
	  margin-left:	auto;
	  margin-right: 2em;
	  padding: 1.5em;
	}

	.without-business-expert > .business-expert {
	  display: none;
	}
}

.rcw-title {
  font-size: 1em !important;
}

.rcw-header > span {
  font-size: 0.8em;
}


@keyframes chat-button-image {
    0% {
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(0);
    }
}

.chat-button-image {
  width: 2em;
  height: 2em;
  animation-name: chat-button-image;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}